.movingTableTop {
  margin-right: 10px;
}
.movingTableTop span a {
  margin: auto 5px;
  cursor: pointer;
}

.links {
  cursor: pointer;
}
