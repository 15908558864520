.dashboardContent {
  margin: 20px;
}
.dashboardCardIcon {
  font-size: 45px;
  color: #ff5829;
}
.dashboardCardValue {
  font-size: 20px;
}
.dashboardCardTitle {
  font-size: 17px;
}
.dashboardChart {
  height: 300px;
}
.links {
  cursor: pointer;
}
.links button {
  background-color: transparent;
  color: var(--rs-text-link);
}
.links button:hover {
  text-decoration: underline;
}
