/* Add all global style needed here
 * INSTRUCTION
 * -----------
 * Create a comment with page title
 * Add all custom css for that page under that comment
 * create a root component id
 * add all css class under that id to avoid overwrite
 */


/******************** Global override design ************/
/* Primary color : #ff5829 */


.text-center{
    text-align: center;
}

.loader{
    position: fixed;
    z-index: 999;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}