/********************* Home ****************/
#home {
  background-color: rgb(240, 242, 245);
  min-height: 100vh;
}
#home .login-panel-flexbox {
  min-height: 100vh;
}
#home .login-panel {
  background-color: white;
  padding-top: 60px;
  position: relative;
  overflow: visible;
}
#home .login-overlay-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -30%);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 88, 41, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  border-radius: 6px;
  height: 80px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: white;
}
#home .logo {
  width: 200px;
}
#home .error {
  color: red;
  margin: 2px;
}
