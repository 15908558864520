/***** SideNav *****/

#side-nav {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100vh;
}

#side-nav .side-bar-container {
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) repeat scroll 0%
    0%;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  border-radius: 0.75rem;

  height: 100%;
}
#side-nav .brand {
  margin-top: 20px;
  color: rgb(255, 255, 255);
}
#side-nav .rs-sidenav-item {
  color: white;
  text-decoration: none;
  padding-left: 10px;
  margin-left: 10px !important;
}
#side-nav .rs-sidenav-item-active {
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)) repeat scroll
    0% 0%;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 0.75rem;
  padding-left: 10px;
  width: auto;
}
.nav-icons {
  margin-right: 15px;
  font-size: 20px;
}
