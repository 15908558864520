.quotationTableTop {
  margin-right: 10px;
}
.quotationTableTop span a {
  margin: auto 5px;
  cursor: pointer;
}
.links {
  cursor: pointer;
}
