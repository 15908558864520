/***** SideNav *****/

#navbar {
  background-color: #ff5829;
  margin-top: 15px;
  margin-right: 15px;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 88, 41, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
}
#navbar h4 {
  color: white;
}
#navbar .profile {
  color: white;
}
